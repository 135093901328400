:root{
  --background-sidebar: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;
}


.navbar {
  background-color: var(--background-sidebar);
  height: 50px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
  z-index: 9000;
  border-bottom: 1px #324d8c solid;
}

.navbar a {padding-left: 20px;}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.5rem;
  background: none;

}

.nav-menu {
  background-color: var(--background-sidebar);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 9000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 9999;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: var(--highlight-mouse-hover-sidebar)
}

.nav-menu-items {
  margin-top: 100px;
  width: 100%;
  z-index: 9999;
}

.navbar-toggle {
  background-color: var(--background-sidebar);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}


.classes-nesting-root{
  color: #fff !important;
}