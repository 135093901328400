@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
:root {
  --primary-color: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px
}

html, body {
  width: 100%;
  color: #3c5ca6;
  color: var(--primary-color);
  overflow: hidden;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.flex {
  display: flex;
}

.fullsize {
  flex-grow: 1;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.center-v {
  align-items: center;
}

.center-h {
  justify-content: center;
}

.justify-content-sa {
  justify-content: space-around;
}

.justify-content-sb {
  justify-content: space-between;
}

.justify-content-se {
  justify-content: space-evenly;
}

.justify-content-c {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.card {
  margin: 10px;
  min-width: 275;
  box-sizing: border-box;
}

.fontRoboto {
  font-family: "Roboto", sans-serif;
}

.accordion {
  max-height: 0px;
  /*define height to start from*/
  transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  /* Firefox 4 */
  -webkit-transition: max-height 0.5s;
  /* Safari and Chrome */
  -o-transition: max-height 0.5s;
  /* Opera */
  overflow: hidden;
}

.accordion.expanded {
  max-height: 1500px
    /*desired height when expanded*/
}

a:link {
  text-decoration: none;
}

a {
  color: inherit;
}

.borda_acima_2_filho:nth-child(n + 3) {
  border-top: 1px #fff solid;
  padding-top: 10px;
}


.sliderAnimation {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */

  transition-property: all;
  transition-duration: 5s;
  transition-timing-function: cubic-bezier(0, 1, 2, 1);
}

.sliderIn {
  position: absolute;
  bottom: 500;
}

.sliderOut {
  position: absolute;
  bottom: -500;
}


@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }
}

@keyframes moveUP {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(300px);
  }
}

@keyframes moveUP {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(300px);
  }
}

@keyframes moveDOWN {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0px);
  }
}

.animation_filter_active {
  animation: zoominoutsinglefeatured 3s infinite;
}



.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3c5ca6;
  /* Safari */
  animation: spin 2s linear infinite;
}

.miniloader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3c5ca6;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes menu-open {
  from {
    margin-left: -210px;
  }

  to {
    margin-left: 0px;
  }
}

.menu_open {
  animation-name: menu-open;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes menu-close {
  from {
    margin-left: 0px;
  }

  to {
    margin-left: -210px;
  }
}

.menu_close {
  animation-name: menu-close;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


.header__menu {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  outline: 1px solid var(--border-accent);
  padding: 64px 12px 0 12px;
  height: 100vh;
  z-index: 9;
}

.ticketsTableMin {
  display: none;
}


.modal_40_70 {
  width: 40%;
  width: 40%;
}

.modal_40_95 {
  min-width: 40%;
  max-width: 40%;
}

.modal_50_80 {
  min-width: 50%;
  max-width: 50%;
}

.modal_50_95 {
  min-width: 50%;
  max-width: 50%;
}

.height_less_header {
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
}

.container_logo_header {
  margin-left: 50px
}


.container_cards_contadores {
  max-width: 94%;
  min-width: 94%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.font3em {
  font-size: 3em;
}


.select_style {
  /* A reset of styles, including removing the default dropdown arrow */
  /* appearance: none; */
  /* Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}


.div_divider_h_2_percent {
  min-width: 2%;
}

.card_contador_chamados {
  color: '#111';
  margin: 10px;
  min-height: 100px;
  max-height: 100px;
  min-width: 200px;
  max-width: 200px;
  cursor: 'pointer'
}



@media print {
  .noprint {
    visibility: hidden;
  }
}

@media only screen and (max-width: 1220px) {
  * {
    font-size: 10;
  }

  .div_divider_h_2_percent {
    min-width: 0px;
  }

  .card_contador_chamados {
    color: '#111';
    margin: 10px;
    min-height: 100px;
    max-height: 100px;
    min-width: 180px;
    max-width: 180px;
    cursor: 'pointer'
  }

  div[class*="menu_open"] {
    animation-name: menu-close;
  }

  div[class*="menu_close"] {
    animation-name: menu-open;
  }

  .container_cards_contadores {
    max-width: 100%;
    min-width: 100%;
    /* display: flex; 
    justify-content: space-between;
    flex-wrap: wrap */
  }

  .ticketsTableFull {
    display: none;
  }

  .ticketsTableMin {
    display: block;
  }

  .modal_40_70 {
    min-width: 70%;
    max-width: 70%;
  }

  .modal_40_95 {
    min-width: 95%;
    max-width: 95%;
  }

  .modal_50_80 {
    min-width: 80%;
    max-width: 80%;
  }

  .modal_50_95 {
    min-width: 95%;
    max-width: 95%;
  }

  .container_logo_header {
    margin-left: 5px
  }
}
:root{
  --background-sidebar: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;
}


.navbar {
  background-color: #3c5ca6;
  background-color: var(--background-sidebar);
  height: 50px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
  z-index: 9000;
  border-bottom: 1px #324d8c solid;
}

.navbar a {padding-left: 20px;}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.5rem;
  background: none;

}

.nav-menu {
  background-color: #3c5ca6;
  background-color: var(--background-sidebar);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 9000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 9999;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #324d8c;
  background-color: var(--highlight-mouse-hover-sidebar)
}

.nav-menu-items {
  margin-top: 100px;
  width: 100%;
  z-index: 9999;
}

.navbar-toggle {
  background-color: #3c5ca6;
  background-color: var(--background-sidebar);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}


.classes-nesting-root{
  color: #fff !important;
}
